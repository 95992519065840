import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageLead from '../components/Page/PageLead'
import PageHero from '../components/Page/PageHero'

const ConsultingPage = ({ location, data }) => {
  const page = data.prismicConsulting.data
  return (
    <Layout title={page.title} headerColor="peach">
      <SEO
        title={page.meta_title || page.title}
        description={page.meta_description || ''}
        location={location}
      />
      <PageLead lead={page.lead.html} color="grey" />
      <PageHero image={page.hero} />
    </Layout>
  )
}

ConsultingPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ConsultingPage

export const pageQuery = graphql`
  query {
    prismicConsulting {
      data {
        title
        hero {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        lead {
          html
        }
        meta_description
        meta_title
      }
    }
  }
`
